import axios from 'axios';
import React, { useEffect } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';


function App() {
  useEffect(() => {
    // eslint-disable-next-line
    trackerManager()
  }, []);

  const trackerManager = async () => {
    try {
      let params = new URL(document.location.toString()).searchParams;
      const token = params.get("token")
      if (token) {
        const resp = await axios.get(`https://api.sandbox.loyalbuds.app/api/v1/admin/marketing/tracker/${token}`)
        console.log(resp.data)
      }
    } catch (e) {
      console.log(e)
    }
    if (isMobile) {
      if (isAndroid)
        window.location.href = "https://play.google.com/store/apps/details?id=com.codinsight.LoyalBuds"
      else if (isIOS)
        window.location.href = "https://apps.apple.com/us/app/loyalbuds/id6476626608?platform=iphone"
      else
        window.location.href = "https://loyalbuds.io/players"
    } else
      window.location.href = "https://loyalbuds.io/players"
  }

  return (
    <p>Redirecting to store... Please wait</p>
  );
}

export default App;
